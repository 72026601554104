<template>
  <div calss="admin">
    <div class="contents context">
      <side-menu class="side-menu" :sideSet="sideSet"></side-menu>
      <div class="main">
        <v-btn
          rounded
          color="#4A148C"
          dark
          v-if="btnYn === 'Y'"
          @click="updateBtnYn('Y')"
        >
          예비회원(001)
        </v-btn>
        <v-btn
          rounded
          color="#0D47A1"
          dark
          v-if="btnYn === 'N'"
          @click="updateBtnYn('N')"
        >
          승인회원(002)
        </v-btn>
        <div class="list">
          <ul id="001list">
            <li class="title"><p>아이디</p></li>
            <li class="title"><p>의사 면허 번호</p></li>
            <li class="title"><p>병원이름</p></li>
            <li class="title"><p>이름</p></li>
            <li class="title"><p>생일</p></li>
            <li class="title"><p>성별</p></li>
            <li class="title"><p>등급</p></li>
            <li class="title"><p>라이센스 이미지</p></li>
            <li class="title"><p>등록일</p></li>
          </ul>
        </div>
        <div
          class="list detail"
          v-for="(listItem, index) in list"
          :key="index + 'A'"
        >
          <div class="list-warp" @click="clickList(listItem)">
            <ul id="002list">
              <li class="detail">
                <p>{{ listItem.drId }}</p>
              </li>
              <li class="detail">
                <p>{{ listItem.licNo }}</p>
              </li>
              <li class="detail">
                <p>{{ listItem.hospNm }}</p>
              </li>
              <li class="detail">
                <p>{{ listItem.drNm }}</p>
              </li>
              <li class="detail">
                <p>{{ listItem.birth }}</p>
              </li>
              <li class="detail">
                <p>{{ listItem.sex }}</p>
              </li>
              <li class="detail">
                <p>{{ listItem.drStatus }}</p>
              </li>
              <li class="detail">
                <label
                  class="attachLic"
                  :class="{ hasFile: listItem.licAttchNm !== null }"
                  @click="licImgView(listItem.licAttchId)"
                  ><p>
                    {{ listItem.licAttchNm !== null ? "Click" : "Null" }}
                  </p></label
                >
              </li>
              <li class="detail">
                <p>{{ listItem.creDt }}</p>
              </li>
            </ul>
          </div>
        </div>

        <div class="pagination-wrap" style="left : 0px">
          <p
            class="navigation-act prev"
            v-if="listStatus.first > 1"
            @click="navigatorAct('prev')"
          >
            <v-icon
              large
              color="teal darken-2"
              style="left : 40px; top : -10px"
            >
              mdi-chevron-left
            </v-icon>
          </p>
          <ul id="pagination">
            <li
              v-for="(pagination, index) in listStatus.currentPaginationList"
              :key="index + 'j'"
              @click="loadList(pagination, index)"
            >
              <p style="top: 7px;">{{ pagination }}</p>
            </li>
          </ul>
          <p
            class="navigation-act next"
            v-if="listStatus.next > 5 && listStatus.last < listStatus.totalPage"
            @click="navigatorAct('next')"
          >
            <v-icon
              large
              color="teal darken-2"
              style="left : -10px; top : -10px"
            >
              mdi-chevron-right
            </v-icon>
          </p>
        </div>
      </div>
    </div>
    <admin-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import AdminPopup from "@/components/modal/AdminPopup";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  components: {
    SideMenu,
    AdminPopup,
  },
  data() {
    return {
      btnYn: "Y",
      sideSet: {
        menuName: "admin",
        sideTitle: "admin",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      list: [],
      popupSet: {},
      listStatus: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalCount: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
    };
  },
  created() {
    this.config("Y");
  },
  mounted() {
    document.querySelector(".adminLic").classList.add("active");
  },
  computed: {
    ...mapGetters("admin", ["GET_PRGS_LIST"]),
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapMutations("admin", ["SET_PRGS_LIST"]),
    ...mapActions("admin", [
      "BRING_PRGS_LIST",
      "BRING_PRGS_LIST_COPY",
      "CHANGE_STATUS",
    ]),
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    updateBtnYn(state) {
      //console.log(state);
      if (state === "Y") {
        this.btnYn = "N";
      } else {
        this.btnYn = "Y";
      }
      this.initPaginationEffect();
      this.config();
    },
    async config() {
      this.initListStatus();
      this.list = [];
      this.listStatus.totalCount = 0;
      this.SET_CHILD_LOADER(true);

      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        if (this.btnYn === "Y") {
          await this.BRING_PRGS_LIST(searchOpt).then(() => {
            //console.log(this.GET_PRGS_LIST);
            this.listStatus.totalCount = this.GET_PRGS_LIST.param.totalCount;
            this.listStatus.currentPage = this.GET_PRGS_LIST.param.pageNo;
            let tempList = JSON.parse(JSON.stringify(this.GET_PRGS_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
          });
        } else {
          await this.BRING_PRGS_LIST_COPY(searchOpt).then(() => {
            //console.log(this.GET_PRGS_LIST.param);
            //console.log(this.GET_PRGS_LIST);
            this.listStatus.totalCount = this.GET_PRGS_LIST.param.totalCount;
            this.listStatus.currentPage = this.GET_PRGS_LIST.param.pageNo;
            let tempList = JSON.parse(JSON.stringify(this.GET_PRGS_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    licImgView(attchId) {
      var url = "https://www.drrefer.net/api/lic/" + attchId;
      var headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      var config = {
        responseType: "blob",
        headers: headers,
      };
      axios.get(url, config).then(function(response) {
        var blob = response.data;
        var u = URL.createObjectURL(blob);
        window.open(u, "_blank");
      });
      //window.open("https://www.drrefer.net/api/lic/" + attchId, "_blank");
    },
    clickList(data) {
      //console.log(data);
      this.popupSet.title = "update drStatus";
      this.popupSet.content =
        "Are you sure about update drStatus this user.\nID : " +
        data.drId +
        "\tName : " +
        data.drNm +
        "\nLic No." +
        data.licNo +
        "\tBirth : " +
        data.birth;
      this.popupSet.popType = "custom";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.nextLink = "/";
      this.popupSet.dataParam = data.drId;
      this.popupSet.imgUrl = data.licAttchId;
      this.SET_POPUP(true);
    },
    initListStatus() {
      this.listStatus.totalCount = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        /*
        console.log(
          "Ds",
          document.querySelector("#pagination").childNodes[k].childNodes[0]
            .textContent
        );
        */
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      this.list = [];
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();
      if (this.listStatus.totalCount >= 1) {
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        if (this.btnYn === "Y") {
          await this.BRING_PRGS_LIST(searchOpt).then(() => {
            //console.log(this.GET_PRGS_LIST);
            this.listStatus.totalCount = this.GET_PRGS_LIST.param.totalCount;
            this.listStatus.totalPage = this.GET_PRGS_LIST.param.totalPage;
            this.listStatus.first = this.GET_PRGS_LIST.param.first;
            this.listStatus.last = this.GET_PRGS_LIST.param.last;
            this.listStatus.prev = this.GET_PRGS_LIST.param.prev;
            this.listStatus.next = this.GET_PRGS_LIST.param.next;
            this.listStatus.currentPage = this.GET_PRGS_LIST.param.pageNo;
            let tempList = JSON.parse(JSON.stringify(this.GET_PRGS_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
          });
        } else {
          await this.BRING_PRGS_LIST_COPY(searchOpt).then(() => {
            //console.log(this.GET_PRGS_LIST);
            this.listStatus.totalCount = this.GET_PRGS_LIST.param.totalCount;
            this.listStatus.totalPage = this.GET_PRGS_LIST.param.totalPage;
            this.listStatus.first = this.GET_PRGS_LIST.param.first;
            this.listStatus.last = this.GET_PRGS_LIST.param.last;
            this.listStatus.prev = this.GET_PRGS_LIST.param.prev;
            this.listStatus.next = this.GET_PRGS_LIST.param.next;
            this.listStatus.currentPage = this.GET_PRGS_LIST.param.pageNo;
            let tempList = JSON.parse(JSON.stringify(this.GET_PRGS_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
          });
        }
      } catch (error) {
        console.log("erorr", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    preParePagination() {
      /**
        this.listStatus.totalCount = 122;
         */
      /** api로 list를 불러온 과정 생략 */
      const totalCount = this.listStatus.totalCount;
      const currentPage = this.listStatus.currentPage;
      //console.log("totalCount", totalCount);
      //console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalCount / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      //console.log("totalPage", totalPage);
      //console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      //console.log("last : " + last);
      //console.log("first : " + first);
      //console.log("next :" + next);
      //console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalCount: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      //console.log(first);
      //console.log(last);
      //console.log(type);
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      for (let i = 0; i <= 5; i++) {
        if (last % 5 !== 0) {
          if (paginationListArray[0] % 5 !== 1) {
            paginationListArray.shift();
          } else {
            this.listStatus.first = paginationListArray[0];
            break;
          }
        }
      }

      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          if (this.listStatus.last % 5 !== 0) {
            let num = this.listStatus.last % 5;
            this.listStatus.last = this.listStatus.last + (5 - num);
            this.loadList(this.listStatus.last, 4);
          } else {
            this.loadList(this.listStatus.last, 4);
          }
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            /*
            console.log(
              "재료",
              document.querySelector("#pagination").childNodes[k].childNodes[0]
                .textContent
            );
            */
            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
        document
          .querySelector("#pagination")
          .childNodes[0].classList.add("active");
      } else {
        if (this.list.length > 0) {
          this.$nextTick((_) => {
            const target = document.querySelectorAll(".tab-wrapper")[
              this.currentTab
            ];
            if (target !== undefined) {
              target
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            //console.log(document.querySelector("#pagination"));
            //console.log((this.listStatus.currentPage % 5) - 1);
            if (this.listStatus.currentPage > 5) {
              if (this.listStatus.currentPage % 5 === 0) {
                document
                  .querySelector("#pagination")
                  .childNodes[4].classList.add("active");
              } else {
                document
                  .querySelector("#pagination")
                  .childNodes[
                    (this.listStatus.currentPage % 5) - 1
                  ].classList.add("active");
              }
            } else {
              document
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            //강제 active 삽입
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;

      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }

      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();

      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
        //console.log(this.listStatus);
        //console.log("123123123");
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/admin";
.btn_style {
  color: #ffffff;
  margin-top: 10px;
  margin-left: 9px;
  float: left;
}
</style>
