<template>
  <div class="contents ">
    <side-menu class="side-menu" :sideSet="sideSet"></side-menu>
    <div class="main">
      <div class="noticeArea">
        <div class="titleArea">
          <p>제목 :</p>
          <input type="text" v-model="notice.title" />
        </div>
        <div class="contentsArea">
          <p>내용 :</p>
          <input type="text" v-model="notice.contents" />
        </div>
        <div class="attachArea">
          <p>첨부파일 :</p>
          <input type="file" @change="onFileChange($event)" />
        </div>
        <div class="btnArea">
          <!-- <button @click="submitNotice()">Submit</button> -->
          <v-btn
            @click="submitNotice()"
            rounded
            color="#156f83"
            dark
          >
            작성 완료
          </v-btn>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: {
    SideMenu, DefaultPopup,
  },
  data() {
    return {
      sideSet: {
        menuName: "admin",
        sideTitle: "admin",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      notice: {
        title: "",
        contents: "",
        attachFile: "",
        attachFileUrl: "",
      },
      popupSet: {},
    };
  },
  mounted() {
    document.querySelector(".adminNotice").classList.add("active");
  },
  computed: {
    ...mapGetters("admin", ["GET_NOTICE_DATA"]),
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("admin", ["ADD_NOTICE_DATA"]),
    ...mapMutations("admin", ["SET_NOTICE_DATA"]),
    async submitNotice() {
      //console.log("this.notice : " , this.notice);
      
      //유효성 검사

      // 1. 제목
      if(this.notice.title === null 
            || this.notice.title === undefined)
            this.notice.title = "";

      if(this.notice.title.length < 1 || 
          this.notice.title.length > 200){
        this.SET_POPUP(true);
        this.popupSet.title = "제목 확인";
        this.popupSet.content = "제목은 1~200자 이내로 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      // 2.내용
      if(this.notice.contents === null 
            || this.notice.contents === undefined)
            this.notice.contents = "";

      if(this.notice.contents.length < 1 || 
          this.notice.contents.length > 65535){
        this.SET_POPUP(true);
        this.popupSet.title = "내용 확인";
        this.popupSet.content = "내용이 없거나 길이가 부적절합니다";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }
      
      const noticeParam = {
        title: this.notice.title,
        conts: this.notice.contents,
      };
      const transedReferParam = JSON.stringify(noticeParam);
      const transData = new FormData();
      transData.append("noticeParam", transedReferParam);
      transData.append("attchFile", this.notice.attachFile);
      /*
      try {
        await this.ADD_NOTICE_DATA(transData).then((data) => {
          //console.log(data);
          this.$router.push("/v/adminMain/2");
        });
      } catch (error) {
        console.log(error);
      }
      */
    },
    onFileChange(event) {
      //console.log(event.target.files[0]);
      this.notice.attachFile = event.target.files[0];
      this.notice.attachFileUrl = URL.createObjectURL(event.target.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/admin";
</style>
