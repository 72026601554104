<template>
  <v-app id="inspire">
    <div calss="admin">
      <div class="contents context">
        <side-menu :sideSet="sideSet"></side-menu>
        <div class="chart_space" style="width : 100%; min-width : 1000px">
          <div style="height : 50px"></div>
          <v-select
            :items="items"
            label="병과 선택"
            dense
            outlined
            v-model="selectedItems"
            @change="getKeywordList(selectedItems)"
          ></v-select>
          <div class="divider" />
          <div style="text-align : center">
            <v-btn
              color="#146f83"
              style="color : #ffffff; width : 200px; height : 50px; font-size : 20px; margin-top : 30px;"
              @click="btnAccept()"
              >키워드 적용</v-btn
            >
          </div>

          <div class="total_keyword wrap">
            <div class="total_keyword_label">
              <h4 class="title">전체키워드</h4>
            </div>
            <div class="total_keyword_box scroll type1">
              <div class="keyword_btn_list">
                <div class="keyword_btn_list">
                  <draggable
                    v-model="keywordBtnList.keyword"
                    :options="{ group: 'people' }"
                    style="min-height: 10px"
                  >
                    <span
                      class="btn_style"
                      v-for="(data, index) in keywordBtnList.keyword"
                      :key="index + 'a'"
                    >
                      <v-btn
                        rounded
                        color="primary"
                        v-if="data.useYn === 'Y'"
                        @click="btnKeyword(index, 'N')"
                        >{{ data.keyword
                        }}<span>({{ data.idxCnt }})</span></v-btn
                      >
                      <v-btn
                        rounded
                        color="#f2f2f2"
                        v-else
                        @click="btnKeyword(index, 'Y')"
                        >{{ data.keyword
                        }}<span>({{ data.idxCnt }})</span></v-btn
                      >
                      <!-- 
                        키워드 삭제(Delete) 아이콘 보류
                      <v-icon
                        dense
                        style="cursor: pointer; 
                      vertical-align : top;"
                        color="red"
                        >mdi-alpha-x-circle-outline</v-icon
                      >
                      -->
                    </span>
                  </draggable>
                </div>
              </div>
            </div>
          </div>

          <div
            clss="oper_list"
            style="width : 520px; float : left; margin-right : 30px; margin-left : 20px;"
          >
            <div class="symp_label">
              <h4 class="title">증상명</h4>
            </div>
            <div class="symp_box scroll type1">
              <div class="symp_btn_list">
                <draggable
                  v-model="sympList"
                  :options="{ group: 'people' }"
                  style="min-height: 10px"
                >
                  <span
                    class="btn_style"
                    v-for="(data, index) in sympList"
                    :key="index + 'a'"
                  >
                    <v-btn
                      rounded
                      color="primary"
                      v-if="data.useYn === 'Y'"
                      @click="btnSymp(index, 'N')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <v-btn
                      rounded
                      color="#f2f2f2"
                      v-else
                      @click="btnSymp(index, 'Y')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <!-- 
                    키워드 삭제(Delete) 아이콘 보류
                  <v-icon
                    dense
                    style="cursor: pointer; 
                  vertical-align : top;"
                    color="red"
                    >mdi-alpha-x-circle-outline</v-icon
                  >
                  -->
                  </span>
                </draggable>
              </div>
            </div>
          </div>

          <div clss="diag_list" style="width : 520px; float : left;">
            <div class="diag_label">
              <h4 class="title">진단명</h4>
            </div>
            <div class="diag_box scroll type1">
              <div class="diag_btn_list">
                <draggable
                  v-model="diagList"
                  :options="{ group: 'people' }"
                  style="min-height: 10px"
                >
                  <span
                    class="btn_style"
                    v-for="(data, index) in diagList"
                    :key="index + 'a'"
                  >
                    <v-btn
                      rounded
                      color="primary"
                      v-if="data.useYn === 'Y'"
                      @click="btnDiag(index, 'N')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <v-btn
                      rounded
                      color="#f2f2f2"
                      v-else
                      @click="btnDiag(index, 'Y')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                  </span>
                </draggable>
              </div>
            </div>
          </div>
          <div style="clear : both" />
          <div
            clss="oper_list"
            style="width : 520px; float : left; margin-right : 30px; margin-left : 20px;"
          >
            <div class="oper_label">
              <h4 class="title">수술명</h4>
            </div>
            <div class="oper_box scroll type1">
              <div class="oper_btn_list">
                <draggable
                  v-model="operList"
                  :options="{ group: 'people' }"
                  style="min-height: 10px"
                >
                  <span
                    class="btn_style"
                    v-for="(data, index) in operList"
                    :key="index + 'a'"
                  >
                    <v-btn
                      rounded
                      color="primary"
                      v-if="data.useYn === 'Y'"
                      @click="btnOper(index, 'N')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <v-btn
                      rounded
                      color="#f2f2f2"
                      v-else
                      @click="btnOper(index, 'Y')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <!-- 
                    키워드 삭제(Delete) 아이콘 보류
                  <v-icon
                    dense
                    style="cursor: pointer; 
                  vertical-align : top;"
                    color="red"
                    >mdi-alpha-x-circle-outline</v-icon
                  >
                  -->
                  </span>
                </draggable>
              </div>
            </div>
          </div>

          <div clss="equip_list" style="width : 520px; float : left;">
            <div class="equip_label">
              <h4 class="title">장비명</h4>
            </div>
            <div class="equip_box scroll type1">
              <div class="equip_btn_list">
                <draggable
                  v-model="equipList"
                  :options="{ group: 'people' }"
                  style="min-height: 10px"
                >
                  <span
                    class="btn_style"
                    v-for="(data, index) in equipList"
                    :key="index + 'a'"
                  >
                    <v-btn
                      rounded
                      color="primary"
                      v-if="data.useYn === 'Y'"
                      @click="btnEquip(index, 'N')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <v-btn
                      rounded
                      color="#f2f2f2"
                      v-else
                      @click="btnEquip(index, 'Y')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                  </span>
                </draggable>
              </div>
            </div>
          </div>

          <div
            clss="etc_list"
            style="clear : both; width : 520px; margin-right : 30px; float : left; margin-left : 20px"
          >
            <div class="etc_label">
              <h4 class="title">기타</h4>
            </div>
            <div class="etc_box scroll type1">
              <div class="etc_btn_list">
                <draggable
                  v-model="etcList"
                  :options="{ group: 'people' }"
                  style="min-height: 10px"
                >
                  <span
                    class="btn_style"
                    v-for="(data, index) in etcList"
                    :key="index + 'a'"
                  >
                    <v-btn
                      rounded
                      color="primary"
                      v-if="data.useYn === 'Y'"
                      @click="btnEtc(index, 'N')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <v-btn
                      rounded
                      color="#f2f2f2"
                      v-else
                      @click="btnEtc(index, 'Y')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                  </span>
                </draggable>
              </div>
            </div>
          </div>

          <div clss="etc_list" style="width : 520px; float : left;">
            <div class="etc_label">
              <h4 class="title">제외</h4>
            </div>
            <div class="etc_box scroll type1">
              <div class="etc_btn_list">
                <draggable
                  v-model="exceptList"
                  :options="{ group: 'people' }"
                  style="min-height: 10px"
                >
                  <span
                    class="btn_style"
                    v-for="(data, index) in exceptList"
                    :key="index + 'a'"
                  >
                    <v-btn
                      rounded
                      color="primary"
                      v-if="data.useYn === 'Y'"
                      @click="btnEtc(index, 'N')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                    <v-btn
                      rounded
                      color="#f2f2f2"
                      v-else
                      @click="btnEtc(index, 'Y')"
                      >{{ data.keyword }}<span>({{ data.idxCnt }})</span></v-btn
                    >
                  </span>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import axios from "axios";
export default {
  components: {
    SideMenu,
    DefaultPopup,
    draggable,
  },
  data() {
    return {
      keywordBtnList: {
        keyword: [],
      },
      diagList: [],
      operList: [],
      sympList: [],
      equipList: [],
      etcList: [],
      exceptList: [],
      popupSet: {},
      sideSet: {
        menuName: "admin",
        sideTitle: "admin",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      items: [
        "소아청소년과",
        "이비인후과",
        "내과",
        "안과",
        "일반의",
        "전문과목",
        "기타",
      ],
      selectedItems: "이비인후과",
    };
  },
  created() {
    this.getKeywordList(this.selectedItems);
  },
  mounted() {
    document.querySelector(".adminKeywordSelection").classList.add("active");
  },
  computed: {
    ...mapGetters("asso", ["GET_KEYWORD_LIST"]),
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapActions("asso", [
      "BRING_KEYWORD_LIST_WITH_KEY",
      "UPDATE_KEYWORD_LIST",
    ]),
    ...mapMutations("basic", ["SET_POPUP"]),
    setKeyStus() {
      let tempList = [];
      for (let i = 0; i < this.keywordBtnList.keyword.length; i++) {
        this.keywordBtnList.keyword[i].keyStus = "W";
        tempList.push(this.keywordBtnList.keyword[i]);
      }
      for (let i = 0; i < this.diagList.length; i++) {
        this.diagList[i].keyStus = "D";
        tempList.push(this.diagList[i]);
      }
      for (let i = 0; i < this.sympList.length; i++) {
        this.sympList[i].keyStus = "S";
        tempList.push(this.sympList[i]);
      }
      for (let i = 0; i < this.operList.length; i++) {
        this.operList[i].keyStus = "O";
        tempList.push(this.operList[i]);
      }
      for (let i = 0; i < this.equipList.length; i++) {
        this.equipList[i].keyStus = "E";
        tempList.push(this.equipList[i]);
      }
      for (let i = 0; i < this.exceptList.length; i++) {
        this.exceptList[i].keyStus = "X";
        tempList.push(this.exceptList[i]);
      }
      for (let i = 0; i < this.etcList.length; i++) {
        this.etcList[i].keyStus = "K";
        tempList.push(this.etcList[i]);
      }
      return tempList;
    },
    async btnAccept() {
      let assoList = [];
      let tempList = this.setKeyStus();

      //console.log(tempList);

      //console.log(this.GET_KEYWORD_LIST.keyword);

      //유효성 검사 및 변경된 데이터 추출
      for (let i = 0; i < this.GET_KEYWORD_LIST.keyword.length; i++) {
        for (let j = 0; j < tempList.length; j++) {
          if (
            this.GET_KEYWORD_LIST.keyword[i].keyword === tempList[j].keyword
          ) {
            if (
              this.GET_KEYWORD_LIST.keyword[i].keyStus !==
                tempList[j].keyStus ||
              this.GET_KEYWORD_LIST.keyword[i].useYn !== tempList[j].useYn
            ) {
              assoList.push(tempList[j]);
            }
          }
        }
      }

      //console.log("assoList : " , assoList);
      const assoParam = {
        assoList: assoList,
      };

      const transData = new FormData();
      const transedAssoParam = JSON.stringify(assoParam);
      transData.append("assoParam", transedAssoParam);
      //console.log("서버로 전송할 data", transedAssoParam);
      await this.UPDATE_KEYWORD_LIST(transData).then((data) => {
        if(data > 900) {
          this.popupSet.title = "키워드 변경 실패";
          this.popupSet.content = "키워드 변경에 실패 했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        this.SET_POPUP(true);
          return;
        }
        this.getKeywordList(this.selectedItems);
        this.popupSet.title = "키워드 변경 완료";
        this.popupSet.content = " 변경된 키워드 적용을 완료 했습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.SET_POPUP(true);
      });
    },
    btnSymp(index, state) {
      this.sympList[index].useYn = state;
    },
    btnEquip(index, state) {
      this.equipList[index].useYn = state;
    },
    btnDiag(index, state) {
      this.diagList[index].useYn = state;
    },
    btnOper(index, state) {
      this.operList[index].useYn = state;
    },
    btnKeyword(index, state) {
      this.keywordBtnList.keyword[index].useYn = state;
    },
    btnEtc(index, state) {
      this.etcList[index].useYn = state;
    },
    async getKeywordList(selectword) {
      let tempList = [];
      this.sympList = [];
      this.diagList = [];
      this.operList = [];
      this.equipList = [];
      this.keywordBtnList.keyword = [];
      this.etcList = [];

      const assoParam = {
        keyword: selectword,
      };

      const transData = new FormData();
      const transedAssoParam = JSON.stringify(assoParam);
      transData.append("assoParam", transedAssoParam);
      //console.log("서버로 전송할 베이직", transedAssoParam);
      await this.BRING_KEYWORD_LIST_WITH_KEY(transData);

      tempList = JSON.parse(JSON.stringify(this.GET_KEYWORD_LIST));

      for (let i = 0; i < tempList.keyword.length; i++) {
        if (tempList.keyword[i].keyStus === "S") {
          this.sympList.push(tempList.keyword[i]);
        }
        if (tempList.keyword[i].keyStus === "D") {
          this.diagList.push(tempList.keyword[i]);
        }
        if (tempList.keyword[i].keyStus === "O") {
          this.operList.push(tempList.keyword[i]);
        }
        if (tempList.keyword[i].keyStus === "E") {
          this.equipList.push(tempList.keyword[i]);
        }
        if (tempList.keyword[i].keyStus === "K") {
          this.etcList.push(tempList.keyword[i]);
        }
        if (tempList.keyword[i].keyStus === "X") {
          this.exceptList.push(tempList.keyword[i]);
        }
        if (tempList.keyword[i].keyStus === "W") {
          this.keywordBtnList.keyword.push(tempList.keyword[i]);
        }
      }
    },
  },
  updated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/admin";

h4.title {
  color: #146f83;
  float: left;
  font-weight: 800;
  display: inline-block;
}
.wrap {
  margin: 20px;
  min-height: 120px;
  width: 1070px;
  display: block;
  clear: both;
}
.label {
  float: "left";
}
.divider_label {
  display: block;
  clear: both;
}
.btn_style {
  color: #ffffff;
  margin-top: 10px;
  margin-left: 9px;
  float: left;
}
.keyword_Manage {
  margin: auto;
  margin-top: 40px;
}
/////////////////////////////////////////////
.scroll {
  padding: 0px 13px 10px 13px !important;
  overflow-y: scroll;
  box-sizing: border-box;
  color: #146f83;
  font-family: "Nanum Gothic";
  margin-right: 50px;
}

/* 스크롤바 설정*/
.type1::-webkit-scrollbar {
  width: 12px;
}

/* 스크롤바 막대 설정*/
.type1::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: #146f83;
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}

.total_keyword_box {
  clear: both;
  width: 1077px;
  height: 250px;
  border: 2px solid #146f83;
}

.oper_box,
.diag_box,
.equip_box,
.symp_box,
.etc_box {
  clear: both;
  width: 528px;
  height: 220px;
  border: 2px solid #146f83;
  margin-bottom: 30px;
}

.keyword_btn_list {
  margin-bottom: 5px;
}
</style>
