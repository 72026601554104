<template>
  <div>
    <div class="context notice withSide">
      <div class="list-detail">
        <div class="header">
          <h5 class="title" v-if="modeUpdate === 0">{{ detailInfo.title }}</h5>
          <input
            type="text"
            class="title"
            v-model="detailInfo.title"
            v-if="modeUpdate === 1"
          />
          <div class="article" v-if="modeUpdate === 0">
            <div class="item">
              <h5 class="title">첨부파일</h5>
              <p
                @click="download()"
                :class="{
                  active:
                    detailInfo.attchId !== null && detailInfo.attchId !== '',
                }"
                v-if="modeUpdate === 0"
              >
                {{ detailInfo.fileNm }}
              </p>
            </div>
          </div>
          <div class="article" v-if="modeUpdate === 1">
            <div class="item">
              <p style="width: 100%">
                첨부파일 : 
                <input
                  type="file"
                  @change="onFileChange($event)"
                  style="width: 80%"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="content">
          <textarea class="content" v-if="modeUpdate === 0" v-model="detailInfo.conts" readonly></textarea>
          <textarea
            type="text"
            class="content"
            v-model="detailInfo.conts"
            v-if="modeUpdate === 1"
          />
        </div>
        <div class="btn-wrap">
          <button type="button" class="active" @click="goPrev()">뒤로가기</button>
          <button type="button" class="active" @click="updateData()">
            수정하기
          </button>
          <button type="button" class="active" @click="deleteData()">
            삭제하기
          </button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: {
    DefaultPopup,
  },
  props: {
    detailInfo: Object,
  },
  data() {
    return {
      modeUpdate: 0,
      notice: {
        title: "",
        contents: "",
        attachFile: "",
        attachFileUrl: "",
      },
    };
  },
  mounted() {
    //console.log(this.detailInfo);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapActions("admin", ["DELETE_NOTICE_DATA", "UPDATE_NOTICE_DATA"]),
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    onFileChange(event) {
      //console.log(event.target.files[0]);
      this.notice.attachFile = event.target.files[0];
      this.notice.attachFileUrl = URL.createObjectURL(event.target.files[0]);
    },
    goPrev() {
      window.location.reload();
    },
    download() {
      if (this.detailInfo.attchId !== null && this.detailInfo.attchId !== "") {
        window.open(
          "https://www.drrefer.net/api/pic/" + this.detailInfo.attchId,
          "_blank"
        );
      }
    },
    async updateData() {
      if (this.modeUpdate === 0) {
        this.modeUpdate = 1;
      } else if (this.modeUpdate === 1) {
        //console.log("noticeId: this.detailInfo.noticeId," , this.detailInfo.noticeId);

        //유효성 검사

        // 1. 공지번호

        if(this.detailInfo.noticeId === null 
          || this.detailInfo.noticeId === undefined)
        this.detailInfo.noticeId = "";

        var noticeIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

        if(!noticeIdBoo.test(this.detailInfo.noticeId)){
          this.SET_POPUP(true);
          this.popupSet.title = "존재하지 않는 공지번호";
          this.popupSet.content = "존재하지 않는 공지번호입니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 2. 제목
        if(this.detailInfo.title === null 
              || this.detailInfo.title === undefined)
              this.detailInfo.title = "";

        if(this.detailInfo.title.length < 1 || 
            this.detailInfo.title.length > 200){
          this.SET_POPUP(true);
          this.popupSet.title = "제목 확인";
          this.popupSet.content = "제목은 1~200자 이내로 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 3. 내용
        if(this.detailInfo.conts === null 
              || this.detailInfo.conts === undefined)
              this.detailInfo.conts = "";

        if(this.detailInfo.conts.length < 1 || 
            this.detailInfo.conts.length > 65535){
          this.SET_POPUP(true);
          this.popupSet.title = "내용 확인";
          this.popupSet.content = "내용이 없거나 길이가 부적절합니다";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        const noticeParam = {
          noticeId: this.detailInfo.noticeId,
          title: this.detailInfo.title,
          conts: this.detailInfo.conts,
        };
        const transedReferParam = JSON.stringify(noticeParam);
        const transData = new FormData();
        transData.append("noticeParam", transedReferParam);
        transData.append("attchFile", this.notice.attachFile);

        try {
          //console.log(transData);
          await this.UPDATE_NOTICE_DATA(transData).then((data) => {
            //console.log(data);
            window.location.reload();
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    async deleteData() {
      
      //유효성 검사

      // 1. 공지번호

      if(this.detailInfo.noticeId === null 
        || this.detailInfo.noticeId === undefined)
      this.detailInfo.noticeId = "";

      var noticeIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

      if(!noticeIdBoo.test(this.detailInfo.noticeId)){
        this.SET_POPUP(true);
        this.popupSet.title = "존재하지 않는 공지번호";
        this.popupSet.content = "존재하지 않는 공지번호입니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      const data = {
        noticeId: this.detailInfo.noticeId,
      };
      try {
        //console.log(data);
        await this.DELETE_NOTICE_DATA(data).then((data) => {
          //console.log(data);
          window.location.reload();
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
.context {
  &.notice {
    display: flex;
  }

  .list-detail {
    width: calc(100% - 244px);
    padding: 0;
    max-width: inherit;
    margin: 133px 0 0 105px;
    min-height: 590px;
  }
}
#app {
  .notice {
    padding: 85px 0 105px 0;
    max-width: 1240px;
    margin: 0 auto;
    position: relative;
  }
}
.list-detail {
  border-top: 4px solid #146f83;
  margin: 55px 0 0 0;
  h5.title {
    font-family: SUIT;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 43px;
    letter-spacing: normal;
    text-align: center;
    color: #313c49;
  }
  input.title {
    width: 100%;
    font-family: SUIT;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 43px;
    letter-spacing: normal;
    text-align: center;
    color: #313c49;
  }
  p {
    font-family: SUIT;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #474747;
  }
  .header {
    h5.title {
      background: #fafafa;
    }
    .article {
      display: flex;
      width: 100%;
      .item {
        display: flex;
        width: 100%;
        h5.title {
          border: 0px solid #707070;
          border-width: 1px 1px 1px 0px;
          width: 130px;
        }
        p {
          border: 0px solid #707070;
          border-width: 1px 0px 1px 0px;
          width: calc(100% - 130px);
          line-height: 43px;
          padding: 0 20px;
          &.active {
            cursor: pointer;
          }
        }
      }
    }
  }
  .content {
    padding: 20px 20px;
    margin: 0 0 60px 0;
    height: 330px;
    border-bottom: 1px solid #707070;
    textarea.content {
      width: 100%;
      height: 100%;
    }
  }
  .btn-wrap {
    display: flex;
    width: 100%;
    justify-content: center;

    button {
      width: 120px;
      border: 0;
      padding: 0;
      font-family: SUIT;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      background: #bcbcbc;
      margin: 0 0 0 12.5px;
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: #146f83;
      }
      &.delete {
        background: red;
      }
    }
  }
}
</style>