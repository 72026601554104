<template>
  <v-app>
    <div calss="admin">
      <div class="contents context">
        <side-menu class="side-menu" :sideSet="sideSet"></side-menu>
        <div class="main">
          <v-btn
            rounded
            color="#0D47A1"
            dark
            v-if="btnYn === 'Y'"
            @click="updateBtnYn('Y')"
          >
            쿠폰 관리
          </v-btn>
          <v-btn
            rounded
            color="#000000"
            dark
            v-if="btnYn === 'N'"
            @click="updateBtnYn('N')"
          >
            사용자 쿠폰 관리
          </v-btn>
          <div class="list couponList1">
            <ul id="001list">
              <li class="title"><p class="list-top">쿠폰번호</p></li>
              <li class="title"><p class="list-top">쿠폰명</p></li>
              <li class="title"><p class="list-top">발행일자</p></li>
              <li class="title"><p class="list-top">분류코드</p></li>
              <li class="title"><p class="list-top">쿠폰금액</p></li>
              <li class="title"><p class="list-top">유효시작일자</p></li>
              <li class="title"><p class="list-top">유효종료일자</p></li>
              <li class="title"><p class="list-top">사용만료여부</p></li>
              <li class="title"><p class="list-top">쿠폰메일발송</p></li>
            </ul>
          </div>
          <div
            class="list detail couponList2"
            v-for="(listItem, index) in list"
            :key="index + 'A'"
          >
            <div class="list-warp">
              <ul id="002list">
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>{{ listItem.cponId }}</p>
                </li>
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>{{ listItem.cponNm }}</p>
                </li>
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>{{ listItem.publDt }}</p>
                </li>
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>
                    {{
                      listItem.prodDvcd === "1"
                        ? "1: 소개디자인"
                        : "2:진료의뢰서"
                    }}
                  </p>
                </li>
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>
                    {{
                      listItem.cponAmt
                        ? listItem.cponAmt.toString().replace("-", "")
                        : ""
                    }}
                  </p>
                </li>
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>{{ listItem.validStrDt }}</p>
                </li>
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>{{ listItem.validEndDt }}</p>
                </li>
                <li class="detail" @click="openCouponDtl(listItem.cponId)">
                  <p>{{ listItem.useYn == "N" ? "만료" : "사용가능" }}</p>
                </li>
                <li class="detail">
                  <v-btn
                    rounded
                    color="#000000"
                    dark
                    @click="openMailSend(listItem.cponId)"
                    v-on:submit.prevent="onSubmit"
                  >
                    쿠폰메일발송
                  </v-btn>
                </li>
              </ul>
            </div>
          </div>
          <div class="list userCouponList1">
            <ul id="001list">
              <li class="title"><p class="list-top">발행번호</p></li>
              <li class="title"><p class="list-top">사용자</p></li>
              <li class="title"><p class="list-top">쿠폰번호</p></li>
              <li class="title"><p class="list-top">쿠폰명</p></li>
              <li class="title"><p class="list-top">쿠폰금액</p></li>
              <li class="title"><p class="list-top">유효시작일자</p></li>
              <li class="title"><p class="list-top">유효종료일자</p></li>
              <li class="title"><p class="list-top">등록일자</p></li>
              <li class="title"><p class="list-top">사용일자</p></li>
              <li class="title"><p class="list-top">사용폐기여부</p></li>
            </ul>
          </div>
          <div
            class="list detail userCouponList2"
            v-for="(listItem, index) in list"
            :key="index + 'B'"
          >
            <div class="list-warp">
              <ul id="002list" @click="openUserCouponDtl(listItem.regiId)">
                <li class="detail">
                  <p>{{ listItem.regiId }}</p>
                </li>
                <li class="detail">
                  <p>{{ listItem.drId }}</p>
                </li>
                <li class="detail">
                  <p>{{ listItem.cponId }}</p>
                </li>
                <li class="detail">
                  <p>{{ listItem.cponNm }}</p>
                </li>
                <li class="detail">
                  <p>
                    {{
                      listItem.cponAmt
                        ? listItem.cponAmt.toString().replace("-", "")
                        : ""
                    }}
                  </p>
                </li>
                <li class="detail">
                  <p>{{ listItem.validStrDt }}</p>
                </li>
                <li class="detail">
                  <p>{{ listItem.validEndDt }}</p>
                </li>
                <li class="detail">
                  <p>{{ listItem.regDt }}</p>
                </li>
                <li class="detail">
                  <p>{{ listItem.useDt ? listItem.useDt : "미사용" }}</p>
                </li>
                <li class="detail">
                  <p>
                    {{
                      listItem.useDt && listItem.useYn == "Y"
                        ? "사용"
                        : listItem.useYn == "Y"
                        ? "폐기"
                        : "사용가능"
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="pagination-wrap" style="left : 0px">
            <p
              class="navigation-act prev"
              v-if="listStatus.first > 1"
              @click="navigatorAct('prev')"
            >
              <v-icon
                large
                color="teal darken-2"
                style="left : 40px; top : -10px"
              >
                mdi-chevron-left
              </v-icon>
            </p>
            <ul id="pagination">
              <li
                v-for="(pagination, index) in listStatus.currentPaginationList"
                :key="index + 'k'"
                @click="loadList(pagination, index)"
              >
                <p style="top: 7px;">{{ pagination }}</p>
              </li>
            </ul>
            <p
              class="navigation-act next"
              v-if="
                listStatus.next > 5 && listStatus.last < listStatus.totalPage
              "
              @click="navigatorAct('next')"
            >
              <v-icon
                large
                color="teal darken-2"
                style="left : -10px; top : -10px"
              >
                mdi-chevron-right
              </v-icon>
            </p>
          </div>
          <div style="text-align : center">
            <v-btn
              class="ma-2"
              outlined
              color="indigo"
              @click="openCouponAdd()"
            >
              쿠폰 추가하기
            </v-btn>
          </div>
        </div>
      </div>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import AdminPopup from "@/components/modal/AdminPopup";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  components: {
    SideMenu,
    AdminPopup,
    DefaultPopup,
  },
  data() {
    return {
      btnYn: "Y",
      sideSet: {
        menuName: "admin",
        sideTitle: "admin",
        activeOrder: 7,
        isWritedSearch: null,
        isWritedPr: null,
      },
      list: [],
      popupSet: {},
      listStatus: {
        totalCount: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalCount: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
    };
  },
  created() {
    this.config();
  },
  mounted() {
    document.querySelector(".adminCoupon").classList.add("active");
  },
  computed: {
    ...mapGetters("admin", [
      "GET_COUPON_LIST",
      "GET_PROD_DATA",
      "GET_USER_COUPON_LIST",
    ]),
    ...mapState("basic", ["checkPopup"]),
  },
  methods: {
    ...mapMutations("admin", ["SET_PRGS_LIST"]),
    ...mapActions("admin", [
      "CHANGE_STATUS",
      "BRING_ADMIN_COUPON_LIST",
      "UPDATE_STATUS_CODE",
      "BRING_PROD_DATA",
      "BRING_ADMIN_USER_COUPON_LIST",
      "SEND_COUPON_EMAIL",
    ]),
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    async config() {
      $(".userCouponList").hide();
      this.currentPage = 0; // 현재 페이지

      this.initListStatus();
      this.list = [];
      let list = [];
      this.SET_CHILD_LOADER(true);
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        if (this.btnYn === "Y") {
          await this.BRING_ADMIN_COUPON_LIST(searchOpt).then(() => {
            //console.log(this.GET_COUPON_LIST);
            this.listStatus.totalCount = this.GET_COUPON_LIST.param.totalCount;
            this.listStatus.currentPage = this.GET_COUPON_LIST.param.pageNo;
            list = JSON.parse(JSON.stringify(this.GET_COUPON_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.preParePagination();
            }, 800);
            setTimeout(() => {
              $(".userCouponList1").hide();
              $(".userCouponList2").hide();
              $(".couponList1").show();
              $(".couponList2").show();
            }, 802);
          });
        } else {
          await this.BRING_ADMIN_USER_COUPON_LIST(searchOpt).then(() => {
            //console.log(this.GET_USER_COUPON_LIST);
            this.listStatus.totalCount = this.GET_USER_COUPON_LIST.param.totalCount;
            this.listStatus.currentPage = this.GET_USER_COUPON_LIST.param.pageNo;
            list = JSON.parse(JSON.stringify(this.GET_USER_COUPON_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.preParePagination();
            }, 800);

            setTimeout(() => {
              $(".couponList1").hide();
              $(".couponList2").hide();
              $(".userCouponList1").show();
              $(".userCouponList2").show();
            }, 802);
          });
        }
      } catch (error) {
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },

    initListStatus() {
      this.listStatus.totalCount = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    updateBtnYn(state) {
      //console.log(state);
      if (state === "Y") {
        this.btnYn = "N";
      } else {
        this.btnYn = "Y";
      }
      this.initPaginationEffect();
      this.config();
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        /*
        console.log(
          "Ds",
          document.querySelector("#pagination").childNodes[k].childNodes[0]
            .textContent
        );
        */
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      //유효성 검사

      //1. 페이지
       if(currentPage === null 
        || currentPage === undefined)
        currentPage = "";
      
      var currentPageBoo = /^[0-9]{1,100}$/;

      if(!currentPageBoo.test(currentPage)){
        this.SET_POPUP(true);
          this.popupSet.title = "페이지 요청 실패";
          this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 페이지 당 보여줄 수
       if(this.listStatus.dataOnPage === null 
        || this.listStatus.dataOnPage === undefined)
        this.listStatus.dataOnPage = "";
      
      var dataOnPageBoo = /^[0-9]{1,100}$/;

      if(!dataOnPageBoo.test(this.listStatus.dataOnPage)){
        this.SET_POPUP(true);
          this.popupSet.title = "페이지 요청 실패";
          this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        return;
      }

      this.list = [];
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();

      /*if (this.listStatus.totalCount >= 1) {
        if (order > this.listStatus.last) {
          order = this.listStatus.last - 1;
        }
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }*/
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        if (this.btnYn === "Y") {
          await this.BRING_ADMIN_COUPON_LIST(searchOpt).then(() => {
            this.listStatus.totalCount = this.GET_COUPON_LIST.param.totalCount;
            this.listStatus.totalPage = this.GET_COUPON_LIST.param.totalPage;
            this.listStatus.first = this.GET_COUPON_LIST.param.first;
            this.listStatus.last = this.GET_COUPON_LIST.param.last;
            this.listStatus.prev = this.GET_COUPON_LIST.param.prev;
            this.listStatus.next = this.GET_COUPON_LIST.param.next;
            this.listStatus.currentPage = this.GET_COUPON_LIST.param.pageNo;
            let tempList = JSON.parse(
              JSON.stringify(this.GET_COUPON_LIST.list)
            );
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
            setTimeout(() => {
              $(".userCouponList1").hide();
              $(".userCouponList2").hide();
              $(".couponList1").show();
              $(".couponList2").show();
            }, 802);
          });
        } else {
          await this.BRING_ADMIN_USER_COUPON_LIST(searchOpt).then(() => {
            this.listStatus.totalCount = this.GET_USER_COUPON_LIST.param.totalCount;
            this.listStatus.totalPage = this.GET_USER_COUPON_LIST.param.totalPage;
            this.listStatus.first = this.GET_USER_COUPON_LIST.param.first;
            this.listStatus.last = this.GET_USER_COUPON_LIST.param.last;
            this.listStatus.prev = this.GET_USER_COUPON_LIST.param.prev;
            this.listStatus.next = this.GET_USER_COUPON_LIST.param.next;
            this.listStatus.currentPage = this.GET_USER_COUPON_LIST.param.pageNo;
            let tempList = JSON.parse(
              JSON.stringify(this.GET_USER_COUPON_LIST.list)
            );
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
            setTimeout(() => {
              $(".couponList1").hide();
              $(".couponList2").hide();
              $(".userCouponList1").show();
              $(".userCouponList2").show();
            }, 802);
          });
        }
      } catch (error) {
        //console.log("erorr", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalCount = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      //console.log("==========================================================");
      //console.log(this.listStatus);
      const totalCount = this.listStatus.totalCount;
      const currentPage = this.listStatus.currentPage;
      //console.log("totalCount", totalCount);
      //console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalCount / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      //console.log("totalPage", totalPage);
      //console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      //console.log("last : " + last);
      //console.log("first : " + first);
      //console.log("next :" + next);
      //console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalCount: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      //console.log(first);
      //console.log(last);
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      for (let i = 0; i <= 5; i++) {
        if (last % 5 !== 0) {
          if (paginationListArray[0] % 5 !== 1) {
            paginationListArray.shift();
          } else {
            this.listStatus.first = paginationListArray[0];
            break;
          }
        }
      }

      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          if (this.listStatus.last % 5 !== 0) {
            let num = this.listStatus.last % 5;
            this.listStatus.last = this.listStatus.last + (5 - num);
            this.loadList(this.listStatus.last, 4);
          } else {
            this.loadList(this.listStatus.last, 4);
          }
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            /*
            console.log(
              "재료",
              document.querySelector("#pagination").childNodes[k].childNodes[0]
                .textContent
            );
            */
            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
        document
          .querySelector("#pagination")
          .childNodes[0].classList.add("active");
      } else {
        if (this.list.length > 0) {
          this.$nextTick((_) => {
            const target = document.querySelectorAll(".tab-wrapper")[
              this.currentTab
            ];
            if (target !== undefined) {
              target
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            //console.log(document.querySelector("#pagination"));
            //console.log((this.listStatus.currentPage % 5) - 1);
            if (this.listStatus.currentPage > 5) {
              if (this.listStatus.currentPage % 5 === 0) {
                document
                  .querySelector("#pagination")
                  .childNodes[4].classList.add("active");
              } else {
                document
                  .querySelector("#pagination")
                  .childNodes[
                    (this.listStatus.currentPage % 5) - 1
                  ].classList.add("active");
              }
            } else {
              document
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            //강제 active 삽입
          });
        }
      }
    },
    navigatorAct(type) {
      //console.log(type);
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    openCouponDtl(cponId) {
      window.open(
        "/v/adminMain/couponDtl/" + cponId,
        "팝업",
        "width=1000px, height=1000px, scrollbars=yes, resizable=no"
      );
    },
    openUserCouponDtl(regiId) {
      window.open(
        "/v/adminMain/userCouponDtl/" + regiId,
        "팝업",
        "width=1000px, height=1000px, scrollbars=yes, resizable=no"
      );
    },
    openCouponAdd() {
      window.open(
        "/v/adminMain/couponAdd",
        "팝업",
        "width=1000px, height=1000px, scrollbars=yes, resizable=no"
      );
    },
    openMailSend(cponId) {
      window.open(
        "/v/adminMain/couponMailSend/" + cponId,
        "팝업",
        "width=1300px, height=1000px, scrollbars=yes, resizable=no"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/admin";
/*
  li{
    border-bottom : 1px solid rgb(190, 187, 187);
  }
  .list-top{
    font-size: 22px;
  }
*/
</style>
