<template>
  <div>
    <div class="admin" v-if="this.$route.params.code === '0'">
      <div class="contents">
        <admin-lic />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '1'">
      <div class="contents cont">
        <admin-notice />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '2'">
      <div class="contents">
        <admin-update />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '3'">
      <div class="contents">
        <admin-feed-back />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '4'">
      <div class="contents">
        <admin-help-desk />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '5'">
      <div class="contents">
        <admin-dash-board />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '6'">
      <div class="contents">
        <admin-keyword-selection />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '7'">
      <div class="contents">
        <admin-black-list />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '8'">
      <div class="contents">
        <admin-manage-payment />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '9'">
      <div class="contents">
        <admin-manage-prod />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === 'prodAdd'">
      <div class="contents">
        <admin-manage-prod-add />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '10'">
      <div class="contents">
        <admin-coupon />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === 'couponAdd'">
      <div class="contents">
        <admin-coupon-add />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === '11'">
      <div class="contents">
        <admin-symptom />
      </div>
    </div>
    <div class="admin" v-if="this.$route.params.code === 'symptomAdd'">
      <div class="contents">
        <admin-symptom-add/>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import AdminNotice from "./AdminNotice";
import AdminLic from "./AdminLic";
import AdminUpdate from "./AdminNoticeUpdate";
import AdminFeedBack from "./AdminFeedBack";
import AdminHelpDesk from "./AdminHelpDesk";
import AdminDashBoard from "./AdminDashBoard";
import AdminKeywordSelection from "./AdminKeywordSelection";
import AdminBlackList from "./AdminBlackList";
import AdminManagePayment from "./AdminManagePayment";
import AdminManageProd from "./AdminManageProd";
import AdminManageProdAdd from "./AdminManageProdAdd.vue";
import AdminCoupon from "./AdminCoupon.vue";
import AdminCouponAdd from "./AdminCouponAdd.vue";
import AdminSymptom from "./AdminSymptom.vue";
import AdminSymptomAdd from "./AdminSymptomAdd.vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  components: {
    SideMenu,
    AdminNotice,
    AdminLic,
    AdminUpdate,
    AdminFeedBack,
    AdminHelpDesk,
    AdminDashBoard,
    AdminKeywordSelection,
    AdminBlackList,
    AdminManagePayment,
    AdminManageProd,
    AdminManageProdAdd,
    AdminCoupon,
    AdminCouponAdd,
    AdminSymptom,
    AdminSymptomAdd,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "admin",
        sideTitle: "admin",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      detailInfo: [],
      popupSet: {},
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
@import "@/assets/css/admin";

</style>
