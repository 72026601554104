<template>
  <v-app>
    <div class="admin">
      <div class="contents context">
        <side-menu class="side-menu" :sideSet="sideSet"></side-menu>
        <div class="main" v-if="!componentState">
          <div class="list">
            <ul id="001list">
              <li class="title"><p class="list-top">번호</p></li>
              <li class="title adu-title"><p class="list-top">제목</p></li>
              <li class="title adu-conts"><p class="list-top">내용</p></li>
              <li class="title adu-file"><p class="list-top">첨부파일</p></li>
            </ul>
          </div>
          <div
            class="list detail"
            v-for="(listItem, index) in list"
            :key="index + 'A'"
          >
            <div class="list-warp">
              <ul id="002list" @click="goDetail(listItem)">
                <li class="detail">
                  <p>{{ index + 1 }}</p>
                </li>
                <li class="detail adu-title">
                  <p>{{ listItem.title }}</p>
                </li>
                <li class="detail adu-conts">
                  <p>
                    {{ listItem.conts }}
                  </p>
                </li>
                <li class="detail adu-file">
                  <p>{{ listItem.fileNm }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="pagination-wrap" style="left : 0px">
            <p
              class="navigation-act prev"
              v-if="listStatus.first > 1"
              @click="navigatorAct('prev')"
            >
              <v-icon
                large
                color="teal darken-2"
                style="left : 40px; top : -10px"
              >
                mdi-chevron-left
              </v-icon>
            </p>
            <ul id="pagination">
              <li
                v-for="(pagination, index) in listStatus.currentPaginationList"
                :key="index + 'j'"
                @click="loadList(pagination, index)"
              >
                <p style="top: 7px;">{{ pagination }}</p>
              </li>
            </ul>
            <p
              class="navigation-act next"
              v-if="
                listStatus.next > 5 && listStatus.last < listStatus.totalPage
              "
              @click="navigatorAct('next')"
            >
              <v-icon
                large
                color="teal darken-2"
                style="left : -10px; top : -10px"
              >
                mdi-chevron-right
              </v-icon>
            </p>
          </div>
        </div>
        <!-- 디테일  -->
        <div class="main" v-if="componentState">
          <admin-notice-detail v-bind:detailInfo="detailInfo" />
          <!-- <div class="noticeArea">
            <div class="titleArea">
              <p>title :</p>
              <input type="text" v-model="detailInfo.title" />
            </div>
            <div class="contentsArea">
              <p>content :</p>
              <input type="text" v-model="detailInfo.contents" />
            </div>
            <div class="attachArea">
              <p>attach :</p>
              <input type="file" @change="onFileChange($event)" />
            </div>
            <div class="btnArea">
              <button @click="submitNotice()">Submit</button>
            </div>
          </div> -->
        </div>
        <admin-popup v-if="checkPopup" :popupSet="popupSet" />
      </div>
    </div>
  </v-app>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import BeatLoader from "vue-spinner/src/BeatLoader";
import AdminNoticeDetail from "./AdminNoticeDetail";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  components: {
    SideMenu, 
    BeatLoader,
    AdminNoticeDetail
  },
  data() {
    return {
      componentState: 0,
      Id: "",
      detailInfo: [],
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "admin",
        sideTitle: "admin",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      popupSet: {},

      setCount: false,
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      listHeader: ["번호", "제목", "내용", "첨부파일"],
      list: [],
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        this.handleGoback();
      };
    }
    //console.log("his", history.pushState);
    this.load();
  },
  mounted() {
    document.querySelector(".adminNoticeUpdate").classList.add("active");
  },
  computed: {
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("notice", ["NOTICE_LIST"]),
    /** 시작일자 종료일자가 0 이면 자동으로 됩니다. 01월01일, 투데이 넣어줌 */
    async goDetail(param) {
      this.detailInfo = param;
      //console.log(this.detailInfo);
      this.componentState = !this.componentState;
      //await this.$router.push(`/v//Detail/${param}`);
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/").catch(()=>{});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },

    initListStatus() {
      this.listStatus.totalDataCnt = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        /*
        console.log(
          "Ds",
          document.querySelector("#pagination").childNodes[k].childNodes[0]
            .textContent
        );
        */
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      //유효성 검사

      //1. 페이지
      if(currentPage === null 
      || currentPage === undefined)
      currentPage = "";
      
      var currentPageBoo = /^[0-9]{1,100}$/;

      if(!currentPageBoo.test(currentPage)){
        this.SET_POPUP(true);
          this.popupSet.title = "페이지 요청 실패";
          this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 페이지 당 보여줄 수
       if(this.listStatus.dataOnPage === null 
        || this.listStatus.dataOnPage === undefined)
        this.listStatus.dataOnPage = "";
      
      var dataOnPageBoo = /^[0-9]{1,100}$/;

      if(!dataOnPageBoo.test(this.listStatus.dataOnPage)){
        this.SET_POPUP(true);
          this.popupSet.title = "페이지 요청 실패";
          this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        return;
      }

      this.list = [];
      const list = [];
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();
      if (this.listStatus.totalDataCnt >= 1) {
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }
      const searchOpt = {
        pageNo: currentPage,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        await this.NOTICE_LIST(searchOpt).then((data) => {
          //console.log("_LIST DATA", data);
          this.listStatus.totalDataCnt = data.data.param.totalCount;
          for (let i = 0; i < data.data.list.length; i++) {
            list.unshift(data.data.list[i]);
          }

          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = list;
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log("erorr", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    preParePagination() {
      /**
        this.listStatus.totalDataCnt = 122;
         */
      /** api로 list를 불러온 과정 생략 */
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      //console.log("totalDataCnt", totalDataCnt);
      //console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      //console.log("totalPage", totalPage);
      //console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      //console.log("last : " + last);
      //console.log("first : " + first);
      //console.log("next :" + next);
      //console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          this.loadList(this.listStatus.last, 4);
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            /*
            console.log(
              "재료",
              document.querySelector("#pagination").childNodes[k].childNodes[0]
                .textContent
            );
            */
            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
        document
          .querySelector("#pagination")
          .childNodes[0].classList.add("active");
      } else {
        if (this.listStatus.totalDataCnt >= 1) {
          this.$nextTick((_) => {
            document
              .querySelector("#pagination")
              .childNodes[this.listStatus.currentPage - 1].classList.add(
                "active"
              );
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    async load() {
      this.initListStatus();
      this.list = [];
      const list = [];
      this.SET_CHILD_LOADER(true);
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
      };
      try {
        await this.NOTICE_LIST(searchOpt).then((data) => {
          //console.log("_LIST DATA", data);
          this.listStatus.totalDataCnt = data.data.param.totalCount;
          for (let i = 0; i < data.data.list.length; i++) {
            list.unshift(data.data.list[i]);
          }

          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.list = list;
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/admin";
@import "@/assets/css/request";
@import "../../assets/scss/main.scss";

.adu-title {
  width: 350px !important;
  overflow: hidden; 
  text-overflow: ellipsis;
}
.adu-conts {
  width: 350px !important;
  overflow: hidden; 
  text-overflow: ellipsis;
}
.adu-file {
  width: 250px !important;
  overflow: hidden; 
  text-overflow: ellipsis;
}
</style>
