<template>
  <div calss="admin">
    <div class="contents context">
      <side-menu :sideSet="sideSet"></side-menu>
      <div class="chart_space">
        <tr>
          <td>
            <div>
              <span>월별 회원 등록 수</span>
              <line-chart :chart-data="datacollection_line"></line-chart>
            </div>
          </td>
          <td>
            <div>
              <span>월별 Refer 등록 수</span>
              <bar-chart :chart-data="datacollection" />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <span>의뢰 병원 Top10</span>
            <doughnut-chart :chart-data="datacollection_topRate_hospital" />
            <span style="position: absolute; top: 81%; left: 21%">
              Top 10
            </span>
          </td>
          <td>
            <span>추천인 Top10</span>
            <doughnut-chart
              :chart-data="datacollection_topRate_recomendPerson"
            />
          </td>
        </tr>

        <!--<bubble-chart />-->
        <!--<div>
          <reactive-chart :chart-data="datacollection" />
          <button class="button is-primary" @click="getReferCount_perMonth()">
            refresh
          </button>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import LineChart from "./chart/LineChart";
import BarChart from "./chart/BarChart";
import BubbleChart from "./chart/BubbleChart.vue";
import ReactiveChart from "./chart/ReactiveChart.vue";
import DoughnutChart from "./chart/DoughnutChart.vue";
import axios from "axios";
export default {
  components: {
    SideMenu,
    LineChart,
    BarChart,
    DoughnutChart,
    BubbleChart,
    ReactiveChart,
  },
  data() {
    return {
      datacollection: {
        labels: [],
        datasets: [],
      }, // instantiating datacollection with null
      datacollection_line: {
        labels: [],
        datasets: [],
      },
      datacollection_topRate_hospital: {
        labels: [],
        datasets: [],
      },
      datacollection_topRate_recomendPerson: {
        labels: [],
        datasets: [],
      },
      sideSet: {
        menuName: "admin",
        sideTitle: "admin",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
    };
  },
  created() {
    this.getReferCount_perMonth();
    this.getRegisteCount_perMonth();
    this.getTopRate_hospital();
    this.getTopRate_recomendPerson();
    this.fillData(); //anytime the vue instance is created, call the fillData() function.
  },
  mounted() {
    document.querySelector(".adminDashBoard").classList.add("active");
  },
  methods: {
    async getTopRate_recomendPerson() {
      var url = "https://www.drrefer.net/api/admin/gettoprate_recomendperson";
      var headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      var config = {
        responseType: "",
        headers: headers,
      };

      await axios.get(url, config).then((response) => {
        //console.log("-----------------------------------------------------");
        //console.log(response.data);

        for (var i = response.data.result.length; i < 10; i++) {
          response.data.result.push({ count: 0, chuId: "" });
        }
        //response.data.result.push({count : 0, chuId: ''});
        //console.log(response.data);
        //console.log("-----------------------------------------------------");

        this.datacollection_topRate_recomendPerson = {
          // Data for the y-axis of the chart
          labels: [
            response.data.result[0].chuId,
            response.data.result[1].chuId,
            response.data.result[2].chuId,
            response.data.result[3].chuId,
            response.data.result[4].chuId,
            response.data.result[5].chuId,
            response.data.result[6].chuId,
            response.data.result[7].chuId,
            response.data.result[8].chuId,
            response.data.result[9].chuId,
          ],
          datasets: [
            {
              label: "추천인 등록 순",
              backgroundColor: [
                "rgba(51,255,204,0.7)",
                "rgba(198,200,238,0.7)",
                "rgba(213,185,178,0.7)",
                "rgba(236,226,208,0.7)",
                "rgba(191,181,175,0.7)",
                "rgba(232,193,197,0.7)",
                "rgba(212,153,185,0.7)",
                "rgba(144,85,162,0.7)",
                "rgba(46,41,78,0.7)",
                "rgba(1,22,56,0.7)",
              ],
              pointBackgroundColor: "white",
              borderWidth: 2,
              pointBorderColor: "#249EBF",
              // Data for the x-axis of the chart
              data: [
                response.data.result[0].count,
                response.data.result[1].count,
                response.data.result[2].count,
                response.data.result[3].count,
                response.data.result[4].count,
                response.data.result[5].count,
                response.data.result[6].count,
                response.data.result[7].count,
                response.data.result[8].count,
                response.data.result[9].count,
              ],
            },
          ],
        };
      });
    },
    async getTopRate_hospital() {
      var url = "https://www.drrefer.net/api/admin/gettoprate_hospital";
      var headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      var config = {
        responseType: "",
        headers: headers,
      };

      await axios.get(url, config).then((response) => {
        //console.log(response);

        for (var i = response.data.result.length; i < 10; i++) {
          response.data.result.push({ count: 0, hospNm: "" });
        }

        this.datacollection_topRate_hospital = {
          // Data for the y-axis of the chart
          labels: [
            response.data.result[0].hospNm,
            response.data.result[1].hospNm,
            response.data.result[2].hospNm,
            response.data.result[3].hospNm,
            response.data.result[4].hospNm,
            response.data.result[5].hospNm,
            response.data.result[6].hospNm,
            response.data.result[7].hospNm,
            response.data.result[8].hospNm,
            response.data.result[9].hospNm,
          ],
          datasets: [
            {
              label: "병원 의뢰 순",
              backgroundColor: [
                "rgba(255, 200, 87,0.7)",
                "rgba(238, 239, 168,0.7)",
                "rgba(234, 225, 81,0.7)",
                "rgba(2, 43, 58,0.7)",
                "rgba(96, 150, 186,0.7)",
                "rgba(238, 241, 189,0.7)",
                "rgba(187, 214, 134,0.7)",
                "rgba(245, 229, 252,0.7)",
                "rgba(178, 103, 94,0.7)",
                "rgba(100, 69, 54,0.7)",
              ],
              pointBackgroundColor: "white",
              borderWidth: 2,
              pointBorderColor: "#249EBF",
              // Data for the x-axis of the chart
              data: [
                response.data.result[0].count,
                response.data.result[1].count,
                response.data.result[2].count,
                response.data.result[3].count,
                response.data.result[4].count,
                response.data.result[5].count,
                response.data.result[6].count,
                response.data.result[7].count,
                response.data.result[8].count,
                response.data.result[9].count,
              ],
            },
          ],
        };
      });
    },
    async getRegisteCount_perMonth() {
      var url = "https://www.drrefer.net/api/admin/getregistecount_permonth";
      var headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      var config = {
        responseType: "",
        headers: headers,
      };

      await axios.get(url, config).then((response) => {
        this.datacollection_line = {
          // Data for the y-axis of the chart
          labels: [
            "1개월전",
            "2개월전",
            "3개월전",
            "4개월전",
            "5개월전",
            "6개월전",
            "7개월전",
            "8개월전",
            "9개월전",
            "10개월전",
            "11개월전",
            "12개월전",
          ],
          datasets: [
            {
              label: "회원 등록수",
              backgroundColor: ["rgba(51,255,204,0.5)"],
              pointBackgroundColor: "white",
              borderWidth: 2,
              pointBorderColor: "#249EBF",
              // Data for the x-axis of the chart
              data: [
                response.data.result.zeroMonthBack,
                response.data.result.oneMonthBack,
                response.data.result.twoMonthBack,
                response.data.result.threeMonthBack,
                response.data.result.fourMonthBack,
                response.data.result.fiveMonthBack,
                response.data.result.sixMonthBack,
                response.data.result.sevenMonthBack,
                response.data.result.eightMonthBack,
                response.data.result.nineMonthBack,
                response.data.result.tenMonthBack,
                response.data.result.elevenMonthBack,
              ],
            },
          ],
        };
      });
    },
    async getReferCount_perMonth() {
      var url = "https://www.drrefer.net/api/admin/getrefercount_permonth";
      var headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
      var config = {
        responseType: "",
        headers: headers,
      };
      await axios.get(url, config).then((response) => {
        //console.log(response.data);

        this.datacollection = {
          // Data for the y-axis of the chart
          labels: [
            "1개월전",
            "2개월전",
            "3개월전",
            "4개월전",
            "5개월전",
            "6개월전",
            "7개월전",
            "8개월전",
            "9개월전",
            "10개월전",
            "11개월전",
            "12개월전",
          ],
          datasets: [
            {
              label: "월별 Refer건수",
              backgroundColor: "rgba(102,206,214,0.5)",
              borderWidth: 2,
              // Data for the x-axis of the chart
              data: [
                response.data.result.zeroMonthBack,
                response.data.result.oneMonthBack,
                response.data.result.twoMonthBack,
                response.data.result.threeMonthBack,
                response.data.result.fourMonthBack,
                response.data.result.fiveMonthBack,
                response.data.result.sixMonthBack,
                response.data.result.sevenMonthBack,
                response.data.result.eightMonthBack,
                response.data.result.nineMonthBack,
                response.data.result.tenMonthBack,
                response.data.result.elevenMonthBack,
              ],
            },
          ],
        };
        //console.log(this.datacollection);
      });
    },
    fillData() {
      this.datacollection = {
        // Data for the y-axis of the chart
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            // Data for the x-axis of the chart
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
          },
        ],
      };
    },
    getRandomInt() {
      // JS function to generate numbers to be used for the chart
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/admin";
</style>
