<script>
//Importing Bar class from the vue-chartjs wrapper
import { Doughnut, mixins } from "vue-chartjs";
//Getting the reactiveProp mixin from the mixins module.
const { reactiveProp } = mixins;
export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  data() {
    return {
      datacollection: null,
      //Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [
            /*{
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },*/
          ],
          xAxes: [
            /*{
              gridLines: {
                display: false,
              },
            },*/
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      data: [],
    };
  },
  created() {
  },
  mounted() {
    //renderChart function renders the chart with the datacollection and options object.

    this.renderChart(this.chartData, this.options);
  },
};
</script>