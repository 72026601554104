<script>
//Importing Bar class from the vue-chartjs wrapper
import { Bar, mixins } from "vue-chartjs";
//Getting the reactiveProp mixin from the mixins module.
const { reactiveProp } = mixins;
export default {
  extends: Bar,
  mixins: [reactiveProp],
  data() {
    return {
      datacollection: {
        //Data to be represented on x-axis
        labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        datasets: [
          {
            label: "월별 refer건수",
            backgroundColor: "#00FFFF",
            pointBackgroundColor: "white",
            borderWidth: 2,
            pointBorderColor: "#249EBF",
            //Data to be represented on y-axis
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      data: [],
    };
  },
  created() {
  },
  mounted() {
    //renderChart function renders the chart with the datacollection and options object.

    this.renderChart(this.chartData, this.options);
  },
};
</script>